import React from 'react'
import Header from '../../components/Header/Header'
import "./Home.css"
import homeImg from "../../images/homeCar.png"
import { useTranslation } from 'react-i18next'

const Home = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='home pb-[150px] relative pt-[20px]'>
				<Header />
				<div className="container mt-[50px] house">
					<div className='flex items-center gap-[80px] home-con'>
						<div className='w-full home-text'>
							<p className='text-[#999] text-[22px] home-p'>{t("logistic")}</p>
							<h1 className='text-white text-[60px] font-bold mb-6 home-h1'>{t("asia")}</h1>

							<div className='home-btn flex items-center gap-4'>
								<button className='btn-link'>
									<a href="#contact">
									{t("contact")}
									</a>
									</button>

								<button className='btn-link'>
									<a href="tel:905469045161">
									 	{t("call")}
									</a>
									</button>
							</div>
						</div>
						<div className='kamaz'>
							<img className='h-auto w-[1000px]' src={homeImg} alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Home