import React from "react";
import service from "../../images/air.jpg";
import "./Service.css"
import { useTranslation } from "react-i18next";

const Services = () => {
	const { t } = useTranslation()

  return (
    <>
      <div id="service" className="service py-[10px]">
        <div className="container">
          <div className="service-con flex gap-4">
            <div>
              <img className="w-[360px] h-[360px] object-cover sm:w-full" src={service} alt="" />
            </div>

            <div>
              <div className="service-text flex flex-col pl-[50px] mb-6">
                <p className="text-blue-500 text-[16px]">
                  {t("service_head")}
                </p>
                <h1 className="hizmet text-[60px] text-[#1a1a2b] font-[500]">
                  {t("service")}
                </h1>
              </div>

              <div className="service-ul flex gap-4">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("azerbaijan")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("georgia")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("ukraine")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("germany")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("kyrgyz")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("swedish")}</span>
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("uz")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("mongolia")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("rus")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("kz")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("poland")}</span>
                  </div>
                  <div className="flex gap-4 text-[#54595f] text-[18px]">
                    <i className="bx bxs-truck text-[24px] text-[#3E4768]"></i>
                    <span>{t("nd")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
