import React from 'react'
import "./Contact.css"
import { useTranslation } from 'react-i18next'


const Contact = () => {

	const { t } = useTranslation()

	return (
		<>
			<div id='contact' className='contact bg-[#f8f8f8] py-[30px]'>
				<div className="container">
					<div className='contact-con flex gap-[150px]'>
						<div className='contact-left flex flex-col gap-8 w-[50%]'>
							<div className='flex items-center gap-4 contact-box'>
								<span><i className='bx bxs-truck text-blue-500 text-[60px]'></i></span>
								<span>
									<h1 className='text-[22px] text-[#1a1a2b]'>{t("processes")}</h1>
									<p className='text-[16px] text-[#777]'>{t("processes_desc")}</p>
								</span>
							</div>
							<hr />
							<div className='flex items-center gap-4 contact-box'>
								<span><i className='bx bx-package text-blue-500 text-[60px]'></i></span>
								<span>
									<h1 className='text-[22px] text-[#1a1a2b]'>{t("partial")}</h1>
									<p className='text-[16px] text-[#777]'>{t("partial")}</p>
								</span>
							</div>
							<hr />
							<div className='flex items-center gap-4 contact-box'>
								<span><i className='bx bxs-phone-call text-blue-500 text-[60px]'></i></span>
								<span>
									<h1 className='text-[22px] text-[#1a1a2b]'>{t("support")}</h1>
									<p className='text-[16px] text-[#777]'>{t("support_desc")}</p>
								</span>
							</div>
						</div>


						<div className='contact-right w-[50%]'>
							<p className='text-[14px] text-blue-500'>{t("choose")}</p>
							<h1 className='con-h1 text-[60px] text-[#222]'>{t("why")}</h1>
							<p className='text-[16px] text-[#777] mb-[30px]'>{t("why_desc")}</p>

							<div className='border-4 border-blue-500 py-[20px] px-[25px] flex gap-4'>
								<i class='bx bx-headphone text-4xl text-blue-500'></i>
								<span>
									<p className='text-[16px] text-[#777]'>{t("call")}</p>
									<a className='phone text-[44px] text-blue-500 font-[500] duration-200 hover:text-[#777]' href="tel:905469045161">+90 546 904 51 61</a>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contact