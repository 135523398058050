import React from 'react'
import "./FooterHead.css"
import { useTranslation } from 'react-i18next'


const FooterHead = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='footer-head bg-[#ff5e14] py-[30px]'>
				<div className='container'>
					<div className='fot-head flex gap-[50px] justify-end items-center'>
						<div className='flex items-center gap-4'>
							<i className='bx bxs-phone-call text-white text-[24px]'></i>
							<span>
								<p className='text-[14px] text-white font-medium'>{t("call")}</p>
								<h1 className='text-white font-[800] text-[16px]'>+90 546 904 51 61</h1>
							</span>
						</div>

						<div className='flex items-center gap-4'>
							<i className='bx bxs-time-five text-white text-[24px]'></i>
							<span>
								<p className='text-[14px] text-white font-medium'>{t("work-time")}</p>
								<h1 className='text-white font-[800] text-[16px]'>{t("week")}</h1>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default FooterHead