import React from 'react'
import Layout from './components/Layout/Layout'
const App = () => {
	return (
		<div>
			<Layout />

		</div>
	)
}

export default App