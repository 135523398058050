import React from 'react'
import "./Blog.css"
import fura from "../../images/FURA.jpeg"
import sklad from "../../images/sklad.jpg"
import ship from "../../images/ship.png"
import { useTranslation } from 'react-i18next'

const Blog = () => {

	const { t } = useTranslation()

	return (
		<>
			<div id='blog' className="blog pt-[80px] pb-4">
				<div className="container">
					<div className='blog-head flex items-center gap-4 px-[10%]'>
						<div>
							<p className='text-[14px] text-blue-500'>{t("blog")}</p>
							<h1 className='text-[60px] text-[#1a1a2b] font-[500]'>{t("blog_head")}</h1>
						</div>

						<div>
							<p className='blog-p italic text-[16px] text-[#777] font-[500] relative'>{t("blog_p")}</p>
						</div>
					</div>

					<div className='blog-box mt-[50px] flex gap-[35px]'>
						<div className=' bg-[#fff] shadow-lg relative cursor-pointer hover:shadow-2xl duration-200'>
							<img className='max-h-[300px] h-full w-full object-cover mb-[30px] blog-img' src={fura} alt="" />

							<div className='bg-blue-500 text-[13px] text-white rounded-[999px] absolute px-[1rem] py-[0.3rem] font-semibold top-[20px] right-[20px] uppercase'>Blog</div>
							<div className='px-[5%] mb-[20%] blog-con'>
								<h1 className='text-[21px] text-[#54595f] font-[600] mb-4'>{t("box-st")}</h1>
								<p className='text-[#777] text-[14px] mb-4'>{t("box-st-p")}</p>
								<p className='text-blue-500 text-[12px] cursor-pointer'>{t("box-btn")}</p>
							</div>
							<hr className='absolute bottom-[35px] w-full' />

							<p className='text-[13px] text-[#adadad] px-[5%] mx-auto w-full absolute bottom-2'>{t("box-bottom")} <span className='px-2'>•</span> {t("box-bt")}</p>	
						</div>

						<div className=' bg-[#fff] shadow-lg relative cursor-pointer hover:shadow-2xl duration-200'>
							<img className='max-h-[300px] h-full w-full object-cover mb-[30px] blog-img' src={ship} alt="" />

							<div className='bg-blue-500 text-[13px] text-white rounded-[999px] absolute px-[1rem] py-[0.3rem] font-semibold top-[20px] right-[20px] uppercase'>Blog</div>
							<div className='px-[5%] mb-[20%] blog-con'>
								<h1 className='text-[21px] text-[#54595f] font-[600] mb-4'>{t("box-nd")}</h1>
								<p className='text-[#777] text-[14px] mb-4'>{t("box-nd-p")}</p>
								<p className='text-blue-500 text-[12px] cursor-pointer'>{t("box-btn")}</p>
							</div>
							<hr className='absolute bottom-[35px] w-full' />

							<p className='text-[13px] text-[#adadad] px-[5%] mx-auto w-full absolute bottom-2'>{t("box-bottom")} <span className='px-2'>•</span> {t("box-bt")}</p>
						</div>

						<div className=' bg-[#fff] shadow-lg relative cursor-pointer hover:shadow-2xl duration-200'>
							<img className='max-h-[300px] w-full h-full object-cover mb-[30px] blog-img' src={sklad} alt="" />

							<div className='bg-blue-500 text-[13px] text-white rounded-[999px] absolute px-[1rem] py-[0.3rem] font-semibold top-[20px] right-[20px] uppercase'>Blog</div>
							<div className='px-[5%] mb-[20%] blog-con'>
								<h1 className='text-[21px] text-[#54595f] font-[600] mb-4'>{t("box-rd")}</h1>
								<p className='text-[#777] text-[14px] mb-4'>{t("box-rd-p")}</p>
								<p className='text-blue-500 text-[12px] cursor-pointer'>{t("box-btn")}</p>
							</div>
							<hr className='absolute bottom-[35px] w-full' />

							<p className='text-[13px] text-[#adadad] px-[5%] mx-auto w-full absolute bottom-2'>{t("box-bottom")} <span className='px-2'>•</span> {t("box-bt")}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Blog