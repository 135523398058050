import React from 'react'
import "./Footer.css"
import { useTranslation } from 'react-i18next'


const Footer = () => {

	const { t } = useTranslation()

	return (
		<>
			<footer className='footer bg-[#222] py-[80px]'>
				<div className="container">
					<div className='fot-our flex justify-between flex-wrap gap-[30px]'>
						<div className='fot-con fot-con-st w-[300px]'>
							<h1 className='text-[18px] text-white font-[500] mb-8 fot-text relative'>{t("about")}</h1>
							<p className='text-[15px] text-[#c2bebe] mb-4'>{t("foot-p")}</p>
							<h1 className='text-white text-[16px] font-[500] mb-4'>{t("fot_address")}</h1>

							<div className='flex items-center gap-2'>
								<a target='_blank' href="https://www.facebook.com/profile.php?id=100060914454356&mibextid=ZbWKwL">
									<i className='bx bxl-facebook text-[#777] text-[18px] bg-[#2b2b2b] p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200'></i>
								</a>
								<a target='_blank' href="https://www.instagram.com/yildirimyazici61">
									<i className='bx bxl-instagram text-[#777] text-[18px] bg-[#2b2b2b] p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200'></i>
								</a>
								<a href="mailto:ltdyildirimlojistik@gmail.com">
									<i className='bx bx-envelope text-[#777] text-[18px] bg-[#2b2b2b] p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200'></i>
								</a>

								<i className='bx bxl-linkedin text-[#777] text-[18px] bg-[#2b2b2b] p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200'></i>

								<i className='bx bxl-vimeo text-[#777] text-[18px] bg-[#2b2b2b] p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200'></i>
							</div>
						</div>


						<div className='fot-con'>
							<h1 className='text-[18px] text-white font-[500] mb-8 fot-text relative'>{t("quick_menu")}</h1>
							<ul className='text-[#c2bebe] text-[16px] font-medium flex flex-col gap-4 pl-[20px]'>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#">{t("home")}</a>
								</li>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#blog">{t("blog")}</a>
								</li>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#about">{t("about")}</a>
								</li>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#service">{t("service")}</a>
								</li>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#contact">{t("contact")}</a>
								</li>
							</ul>
						</div>

						<div className='fot-con'>
							<h1 className='text-[18px] text-white font-[500] mb-8 fot-text relative'>{t("site")}</h1>
							<ul className='text-[#c2bebe] text-[16px] font-medium flex flex-col gap-4 pl-[20px]'>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#">{t("privacy")}</a>
								</li>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#">{t("cookie")}</a>
								</li>
								<li className='list-disc hover:text-blue-500 duration-200'>
									<a className='hover:text-blue-500 duration-200' href="#">{t("terms")}</a>
								</li>
							</ul>
						</div>

						<div className='fot-con'>
							<h1 className='text-[18px] text-white font-[500] mb-8 fot-text relative'>{t("contact")}</h1>
							<div className='flex flex-col gap-10'>
								<div className='flex gap-4'>
									<i className='bx bxs-phone-call text-blue-500 text-[30px]'></i>
									<span className='flex flex-col gap-2'>
										<p className='text-[#c2bebe] font-medium text-[15px]'>{t("phone")}</p>
										<a href="tel:+905469045161" className='text-white font-bold duration-200 hover:text-blue-500'>+90 546 904 51 61</a>
										<a href="tel:+995555466169" className='text-white font-bold duration-200 hover:text-blue-500'>+99 555 546 61 69</a>
										<a href="tel:+998949045161" className='text-white font-bold duration-200 hover:text-blue-500'>+99 894 904 51 61</a>
										<a href="tel:+905304045161" className='text-white font-bold duration-200 hover:text-blue-500'>+90 530 404 51 61</a>
									</span>
								</div>
								<div className='flex items-center gap-4'>
									<i className='bx bx-time text-blue-500 text-[30px]'></i>
									<span>
										<p className='text-[#c2bebe] font-medium text-[15px]'>{t("work-time")}</p>
										<h1 className='text-white font-bold'>{t("week")}</h1>
									</span>
								</div>


								<div className='flex items-center gap-4'>
									<i className='bx bx-envelope text-blue-500 text-[30px]'></i>
									<span className='flex flex-col gap-2'>
										<p className='text-[#c2bebe] font-medium text-[15px]'>{t("mail")}</p>
										<a href="mailto:ltdyildirimlojistik@gmail.com" className='text-white font-bold duration-200 hover:text-blue-500'>ltdyildirimlojistik@gmail.com</a>
										<a href="mailto:Of061li@gmail.com" className='text-white font-bold duration-200 hover:text-blue-500'>Of061li@gmail.com</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer