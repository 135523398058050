import React from 'react'
import "./About.css"
import keme from "../../images/post-09.jpg"
import logo from "../../images/logoo.png"
import { useTranslation } from 'react-i18next'

const About = () => {
	const { t } = useTranslation()
	return (
		<>
			<div id='about' className="about pt-[30px] pb-[80px]">
				<div className="container">
					<div className='flex justify-between about-con'>
						<div>
							<p className='text-blue-500 text-[14px]'>{t("about")}</p>
							<h1 className='biz font-bold text-[60px] text-[#222]'>{t("who")}</h1>
							<p className='about-p text-[16px] text-[#777] mb-[50px] font-[500] w-[500px]'>{t("who_desc")}</p>

							<button className='flex items-center gap-2 text-white bg-blue-500 px-[35px] h-[60px]'>
								<span className='text-[16px] font-bold'>{t("more")}</span>
								<i className='bx bxs-right-arrow-alt text-[16px] font-bold'></i>
							</button>
						</div>

						<div className='flex'>
							<div className='flex-col inline-flex gap-2 bg-blue-500 text-white w-[205px] h-[200px]'>
								<i className='bx bx-package text-[80px] m-auto'></i>
								<span className='m-auto'>
									<h1 className='text-[36px] font-bold'>2000+</h1>
									<p className='text-[14px]'>{t("delivery")}</p>
								</span>
							</div>
							<div className='relative'>
								<img className="h-[200px]" src={keme} alt="" />

								<div className='about-logo bg-[#fff] w-[200px] h-[200px] absolute top-[-70px] py-[40px]'>
									<img className='h-[125px] m-auto' src={logo} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default About