import React from 'react'
import "./Transportation.css"
import blogKamaz from "../../images/blog-kamaz.jpg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const Transportation = () => {

	const { t } = useTranslation()

	let settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
	};

	return (
		<>
			<div className='bg-[#222] py-[100px] overflow-x-hidden max-w-[100%]'>
					<div className='tran flex gap-4 justify-between pr-[5%]'>
						<div className='tran-left pl-[10%]'>
							<h1 className='on-eki'>12</h1>
							<h1 className='tran-h1 text-white font-bold text-[60px]'>{t("transport_zone")}</h1>
							<p className='text-[16px] text-[#777] font-[500]'>{t("transport_desc")}</p>
						</div>

						<div className='slide-con'>
							<Slider { ...settings } className='h-[300px] w-[500px] slide'>
									<div className=''>
										<img className='w-[500px] h-[300px] object-cover' src={blogKamaz} alt="" />
									</div>
									<div className=''>
										<img className='w-[500px] h-[300px] object-cover' src={blogKamaz} alt="" />
									</div>
									<div className=''>
										<img className='w-[500px] h-[300px] object-cover' src={blogKamaz} alt="" />
									</div>
									<div className=''>
										<img className='w-[500px] h-[300px] object-cover' src={blogKamaz} alt="" />
									</div>
							</Slider>
						</div>
					</div>
			</div>
		</>
	)
}

export default Transportation