import React from 'react'
import Home from '../../screens/Home/Home'
import About from '../../screens/About/About'
import Transportation from '../../screens/Transportation/Transportation'
import Services from '../../screens/Services/Services'
import Contact from '../../screens/Contact/Contact'
import Blog from '../../screens/Blog/Blog'
import FooterHead from '../../screens/FooterHead/FooterHead'
import Footer from '../Footer/Footer'

const Layout = () => {
	return (
		<div>
			<Home />
			<About />
			<Transportation />
			<Services />
			<Contact />
			<Blog />
			<FooterHead />
			<Footer />
		</div>
	)
}

export default Layout