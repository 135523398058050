import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../../images/logoo.png";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const Header = () => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const locales = t("locales", { returnObjects: true }) || [];
  const [local, setLocal] = useState(i18n.language);


  const dropdownMenu = locales.map((item) => ({
    key: item.local,
    label: (
      <div
        onClick={() => {
          i18n.changeLanguage(item.local).then((r) => console.log(r));
          setLocal(item.local);
          console.log(i18n.language);
        }}
        className="flex gap-4 items-center justify-between"
      >
        <h1 className="text-[18px] font-medium">{item.label}</h1>
        <span className={`text-[28px] fi fi-${item.flag}`}> </span>
      </div>
    ),
  }));


  return (
    <>
      <div className="header">
        <div className="container bg-white header-con">
          <nav className="h-full flex items-center justify-between gap-4">
            <div className="flex h-full items-center gap-[30px]">
              <div
                onClick={() => setOpen(!open)}
                className="menu bg-gray-100 h-[100px] w-[100px]"
              >
                <button className="m-auto h-full w-full">
                  <i className=" bx bx-menu text-[50px] text-[#222]"></i>
                </button>
              </div>
              <div>
                <img className="h-[100px] cursor-pointer" src={logo} alt="" />
              </div>
            </div>

            <div>
              <ul className="nav-menu flex items-center gap-4">
                <li>
                  <a
                    className="text-[#777] font-bold text-[14px] duration-200 hover:text-blue-500"
                    href="#"
                  >
                    {t("home")}
                  </a>
                </li>
                <li>
                  <a
                    className="text-[#777] font-bold text-[14px] duration-200 hover:text-blue-500"
                    href="#blog"
                  >
                    {t("blog")}
                  </a>
                </li>
                <li>
                  <a
                    className="text-[#777] font-bold text-[14px] duration-200 hover:text-blue-500"
                    href="#about"
                  >
                    {t("about")}
                  </a>
                </li>
                <li>
                  <a
                    className="text-[#777] font-bold text-[14px] duration-200 hover:text-blue-500"
                    href="#service"
                  >
                    {t("service")}
                  </a>
                </li>
                <li>
                  <a
                    className="text-[#777] font-bold text-[14px] duration-200 hover:text-blue-500"
                    href="#contact"
                  >
                    {t("contact")}
                  </a>
                </li>
              </ul>
            </div>

            <Dropdown
              className="drawer px-10 z-0"
              menu={{ items: dropdownMenu }}
            >
              <div className=" flex justify-between items-center  cursor-pointer gap-x-3 navbar__locales z-[9999999]">
                <div className="flex items-center gap-x-[10px]  z-[9999999]">
                  <span
                    className={`text-[28px] fi fi-${
                      locales.find((x) => x.local === local)?.flag
                    }`}
                  ></span>
                </div>
                <i className="bx bx-chevron-down text-[#777] text-3xl"></i>
              </div>
            </Dropdown>
          </nav>
        </div>
      </div>

      {/* Sidebar */}

      <div className={`sidebar z-[9999] px-4 overflow-x-hidden ${open ? "active" : ""}`}>
        <div>
          <span className="flex justify-between gap-4">
            <img className="mb-4 cursor-pointerz" src={logo} alt="" />
            <i
              onClick={() => setOpen(false)}
              className="bx bx-x text-[#000046] text-[35px] cursor-pointer"
            ></i>
          </span>

          <div>
            <p className="sidebar-text text-[#000046] text-[18px] font-[500]">
              {t("sidebar-p")}
            </p>

            <ul className="sidebar-menu hidden flex flex-col gap-4">
              <li className="mb-4">
                <a
                  onClick={() => setOpen(!open)}
                  className="text-[#777] font-bold text-[18px] duration-200 hover:text-blue-500"
                  href="#"
                >
                  {t("home")}
                </a>
              </li>
              <li className="mb-4">
                <a
                  onClick={() => setOpen(!open)}
                  className="text-[#777] font-bold text-[18px] duration-200 hover:text-blue-500"
                  href="#blog"
                >
                  {t("blog")}
                </a>
              </li>
              <li className="mb-4">
                <a
                  onClick={() => setOpen(!open)}
                  className="text-[#777] font-bold text-[18px] duration-200 hover:text-blue-500"
                  href="#about"
                >
                  {t("about")}
                </a>
              </li>
              <li className="mb-4">
                <a
                  onClick={() => setOpen(!open)}
                  className="text-[#777] font-bold text-[18px] duration-200 hover:text-blue-500"
                  href="#service"
                >
                  {t("service")}
                </a>
              </li>
              <li className="mb-4">
                <a
                  onClick={() => setOpen(!open)}
                  className="text-[#777] font-bold text-[18px] duration-200 hover:text-blue-500"
                  href="#contact"
                >
                  {t("contact")}
                </a>
              </li>
            </ul>

            <div className="mt-8">
              <h1 className="text-white font-bold text-[24px]">
                {t("contact_info")}
              </h1>

              <div className="flex flex-col gap-4 mt-8">
                <div className="flex gap-2 items-center duration-200 text-[#000046] hover:text-blue-600 text-[18px] font-[500]">
                  <i className="bx bx-map"></i>
                  <a href="#" className="text-[16px]">
                    {t("address")}
                  </a>
                </div>

                <div className="flex gap-2 items-center duration-200 text-[#000046] hover:text-blue-600 text-[18px] font-[500]">
                  <i className="bx bxs-phone"></i>
                  <a href="tel:+905469045161" className="text-[16px]">
									90 546 904 51 61
                  </a>
                </div>

                <div className="flex gap-2 items-center duration-200 text-[#000046] hover:text-blue-600  text-[18px] font-[500]">
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:ltdyildirimlojistik@gmail.com" className="text-[16px]">
									ltdyildirimlojistik@gmail.com
                  </a>
                </div>
              </div>

              <div className="flex items-center gap-2 mt-8">
								<a target="_blank" href="https://www.facebook.com/profile.php?id=100060914454356&mibextid=ZbWKwL">
                	<i className="bx bxl-facebook text-[#000046] text-[18px] bg-gray-200 p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200"></i>
								</a>
									<a target="_blank" href="https://www.instagram.com/yildirimyazici61">
                		<i className="bx bxl-instagram text-[#000046] text-[18px] bg-gray-200 p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200"></i>
									</a>

									<a href="mailto:ltdyildirimlojistik@gmail.com">
									<i className='bx bx-envelope text-[#000046] text-[18px] bg-gray-200 p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200'></i>
								</a>
								
                <i className="bx bxl-linkedin text-[#000046] text-[18px] bg-gray-200 p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200"></i>

                <i className="bx bxl-vimeo text-[#000046] text-[18px] bg-gray-200 p-2 cursor-pointer hover:bg-blue-500 hover:text-white duration-200"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
